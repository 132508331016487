export default [
    {
        title: 'Page',
        key: 'PageName',
        minWidth: 100,
    },
	{
        title: 'Brief',
        key: 'Brief',
        minWidth: 100,
    },
]
