 <template>
        <FormWrapper :is-loading="isLoading">
            <PageTitle title="Manage Help Content" slot="title" link="/helpContent/HelpContent List" />
            <ValidationObserver ref="validator" >
                <FormRow>
                    <div class="col-sm-3">
                        <InputText v-model="filtering.Name" style="width: 200px" label="Page Name" />
                    </div>
                    <div class="col-sm-3">
                        <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                        <Icon type="ios-search" />
                    </FormButton>
                    </div>
                </FormRow>
            </ValidationObserver>
            <DataTable :actions="true"
                :data="helpList"
                :columns="tableConfig"
                :is-loading="isLoading"
                @on-page-change="pageChange"
                :pagination="pagination"
                @on-sort-change="sortChange">
            </DataTable>
        </FormWrapper>
    </template>
    <script>
        import FormWrapper from 'Components/form/FormWrapper';
        import PageTitle from 'Components/layout/PageTitle';
        import DataTable from 'Components/DataTable'
        import loadingMixin from 'Mixins/loadingMixin'
        import {getHelpContentList} from '../api'
        import tableConfig from './table'
        import { ValidationObserver } from 'vee-validate'
        export default {
            name: "List",
            mixins: [loadingMixin],
            components: {
                FormWrapper,
                DataTable,
                PageTitle,
                ValidationObserver
            },
            data() {
                return {
                    filtering: {
                        Name: ''
                    },
                    sorting: {
                        key: 'NAME',
                        order: 'asc'
                    },
                    pagination: {
                        perPage: 10,
                        page: 1,
                        total: 0
                    },
                    tableConfig,
                    helpList: [],
                    showMessage:{
                        isVisible: false,
                        message: ''
                    },
                }
            },
            computed: {
              userData() {
                  return this.$store.state.user.user
                }
            },
            watch: {
            },
            created() {
                this.getDataByFilter()
            },
            methods: {
                search(){
                    this.pagination.page = 1;
                    this.getDataByFilter()
                },
                sortChange(data) {
                    this.sorting.key = data.key;
                    this.sorting.order = data.order;
                    this.getDataByFilter()
                },
                getDataByFilter() {
                    setTimeout(() => {
                        this.$refs.validator.validate().then((result) => {
                            if (result) {
                                this.showLoader();
                                const { key, order } = this.sorting;
                                const { perPage, page } = this.pagination;
                                const filters = {};
                                if (this.filtering.Name) {
                                    filters.Name = this.filtering.Name;
                                }
                                const data = {
                                    filterjson: {
                                        filter: [filters],
                                        sort: [{ key, order }],
                                        paging: [
                                            {
                                                startIndex: page,
                                                pageSize: perPage
                                            }
                                        ]
                                    }
                                };
                                getHelpContentList(data)
                                    .then(this.handleResponse)
                                    .catch(this.handleError)
                            }
                        })
                    },
                    50
                    )
                },
                pageChange(page) {
                    this.pagination.page = page;
                    this.getDataByFilter()
                },
                handleResponse(response) {
                    this.hideLoader();
                    this.helpList = response.data;
                    this.pagination.total = response.NumberOfRecords;
                },
            }
        }
    </script>
    
    <style lang="scss" module>
        .pagination {
            margin: 10px 0 0;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            line-height: 26px;
        }
    
        .paginationText {
            margin: 0 20px;
        }
    
        .paginationButton {
            cursor: pointer;
            width: 26px;
            text-align: center;
            transition: background-color .2s ease-in-out,color .2s ease-in-out, box-shadow 200ms ease
        }
    
        .paginationButton:hover {
            background: #57a3f3;
            color: #fff;
            &:hover {
                box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
            }
        }
    
        .filter {
            margin: -10px 0 10px;
        }
    
        .filterItem {
            display: inline-block;
            margin: 0 15px 15px 0;
        }
    
        .filterItemCheckbox {
            flex-basis: auto;
            align-self: flex-start;
            margin-top: 13px;
        }
    </style>
    